import { createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createBlock as _createBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = (n: any) => (_pushScopeId("data-v-9ef2867a"),n=n(),_popScopeId(),n)
const _hoisted_1 = {
  id: "pageList",
  class: "XyzList"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_el_col = _resolveComponent("el-col")!
  const _component_el_input = _resolveComponent("el-input")!
  const _component_el_row = _resolveComponent("el-row")!
  const _component_el_option = _resolveComponent("el-option")!
  const _component_el_select = _resolveComponent("el-select")!
  const _component_el_table_column = _resolveComponent("el-table-column")!
  const _component_ListEngine = _resolveComponent("ListEngine")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_ListEngine, {
      pageList: _ctx.pageList,
      ref: "pageListRef",
      formatPageInfo: _ctx.formatPageInfo
    }, {
      queryParams: _withCtx(() => [
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("信用证号：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[0] || (_cache[0] = e => _ctx.pageList.queryParam.code = _ctx.valid(e)),
                  placeholder: "请输入信用证号",
                  modelValue: _ctx.pageList.queryParam.code,
                  "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.pageList.queryParam.code) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("客户名称：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_input, {
                  onInput: _cache[2] || (_cache[2] = e => _ctx.pageList.queryParam.customerName = _ctx.valid(e)),
                  placeholder: "请输入客户名称",
                  modelValue: _ctx.pageList.queryParam.customerName,
                  "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.pageList.queryParam.customerName) = $event)),
                  clearable: ""
                }, null, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        }),
        _createVNode(_component_el_row, { class: "myRow" }, {
          default: _withCtx(() => [
            _createVNode(_component_el_col, {
              span: 3,
              class: "myColTitle"
            }, {
              default: _withCtx(() => [
                _createTextVNode("审批状态：")
              ]),
              _: 1
            }),
            _createVNode(_component_el_col, { span: 9 }, {
              default: _withCtx(() => [
                _createVNode(_component_el_select, {
                  modelValue: _ctx.pageList.queryParam.status,
                  "onUpdate:modelValue": _cache[4] || (_cache[4] = ($event: any) => ((_ctx.pageList.queryParam.status) = $event)),
                  placeholder: "请选择审批状态",
                  style: {"width":"100%"},
                  clearable: ""
                }, {
                  default: _withCtx(() => [
                    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comboSelect({comboId:'status'}), (item) => {
                      return (_openBlock(), _createBlock(_component_el_option, {
                        key: item.value,
                        label: item.label,
                        value: item.value,
                        disabled: item.disabled
                      }, null, 8, ["label", "value", "disabled"]))
                    }), 128))
                  ]),
                  _: 1
                }, 8, ["modelValue"])
              ]),
              _: 1
            })
          ]),
          _: 1
        })
      ]),
      extendSlot: _withCtx(() => []),
      tbCols: _withCtx(() => [
        _createVNode(_component_el_table_column, {
          prop: "F_CODE",
          label: _ctx.$t('xyz.columns.F_CODE')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_BZ",
          label: _ctx.$t('xyz.columns.F_BZ')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_AMOUNT",
          label: "信用证金额",
          "header-align": "center",
          align: "right"
        }, {
          default: _withCtx((scope) => [
            _createElementVNode("span", null, _toDisplayString(_ctx.utils.UtilPub.toAmountFormat(scope.row.F_AMOUNT,false,2)), 1)
          ]),
          _: 1
        }),
        _createVNode(_component_el_table_column, {
          prop: "F_KZRQ",
          label: _ctx.$t('xyz.columns.F_KZRQ')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_YXQ",
          label: _ctx.$t('xyz.columns.F_YXQ')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_ZCZQ",
          label: _ctx.$t('xyz.columns.F_ZCZQ')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_ZCJDR",
          label: _ctx.$t('xyz.columns.F_ZCJDR')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_CUSTOMER_NAME",
          label: _ctx.$t('xyz.columns.F_CUSTOMER_ID')
        }, null, 8, ["label"]),
        _createVNode(_component_el_table_column, {
          prop: "F_STATUS_NAME",
          label: "流程状态"
        })
      ]),
      _: 1
    }, 8, ["pageList", "formatPageInfo"])
  ]))
}